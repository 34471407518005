import React from "react"
import { graphql, StaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import Typewriter from "typewriter-effect"
import FormContato from "../components/formContato"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ParaVoce = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO title="Para Você - Sua carreira" />
      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <header className="post-content-header">
            <h2>
              <Typewriter
                options={{
                  autoStart: true,
                  loop: true,
                  delay: 55,
                }}
                onInit={typewriter => {
                  typewriter
                    .typeString(
                      'O que <span style="color: #26BBBA">você quer</span>?'
                    )
                    .changeDeleteSpeed(40)
                    .pauseFor(1000)
                    .deleteChars(10)
                    .typeString(
                      '<span style="color: #26BBBA">sua idade permite</span>?'
                    )
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString(
                      'Quais são <span style="color: #26BBBA">suas crenças</span>?'
                    )
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString(
                      'O que você se <span style="color: #26BBBA">sente capaz</span>?'
                    )
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString(
                      'Eu posso me <span style="color: #26BBBA">reinventar</span>?'
                    )
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString(
                      '<span style="color: #79625d">Amplie suas oportunidades!</span>'
                    )
                    .pauseFor(2000)
                    .start()
                }}
              />
            </h2>
          </header>

          <div className="row" style={{ width: `100%` }}>
            <div className="col-4">
              <div
                style={{
                  padding: "1rem 0",
                  textAlign: "center",
                  background: "#ffffff",
                  fontSize: "16px",
                }}
              >
                <a href="#DesignCarreira">Design de Carreira</a>
              </div>
            </div>

            <div className="col-4">
              <div
                style={{
                  padding: "1rem 0",
                  textAlign: "center",
                  background: "#ffffff",
                  fontSize: "16px",
                }}
              >
                <a href="#Seniores">No Age | Exclusivo para 50+</a>
              </div>
            </div>

            <div className="col-4">
              <div
                style={{
                  padding: "1rem 0",
                  textAlign: "center",
                  background: "#ffffff",
                  fontSize: "16px",
                }}
              >
                <a href="#ImagemComportamento">Imagem & Comportamento</a>
              </div>
            </div>
          </div>

          <br />
          <br></br>

          <div
            id="DesignCarreira"
            className="row transition-fade"
            style={{
              width: `100%`,
              padding: "10px",
              textAlign: "left",
              background: "#ffffff",
              fontSize: "16px",
              verticalAlign: "center",
            }}
          >
            <div className="col-4" style={{ textAlign: `center` }}>
              <Img
                fluid={data.designCarreira.childImageSharp.fluid}
                className="kg-image"
              />

              <br />
              <br />
              <Link to="/contato" className="calltoaction-button">
                Mais Informações
              </Link>
            </div>
            <div className="col-8">
              <b style={{ fontSize: `20px`, color: `#26BBBA` }}>
                Design de Carreira
              </b>
              <br />
              <p>
                Vivemos um momento de oportunidades para aqueles que acreditam
                que os caminhos são múltiplos e que conhece suas fortalezas.
              </p>
              <p>
                O plano rígido de carreira, aquele construído desde a
                Universidade ou oferecidos pela empresa como Junior, Pleno,
                Sênior, etc, dentro de uma mesma área, abriu espaço para uma
                forma protagonista e orgânica de carreira, baseada em suas
                escolhas ou, como mentores de carreira gostam de dizer, naquilo
                que você é realmente bom e cujo trabalho tem maior significado.
              </p>
              <p>
                Estar aberto para reconhecer suas fortalezas, testar
                possibilidades para percorrer novos caminhos e ampliar
                experiências é um dos ingredientes para o processo de design de
                carreira que trará mais clareza sobre o que você quer fazer na
                sua vida profissional.
              </p>
            </div>
          </div>

          <br />

          <div
            id="Seniores"
            className="row"
            style={{
              width: `100%`,
              padding: "10px",
              textAlign: "left",
              background: "#ffffff",
              fontSize: "16px",
              verticalAlign: "center",
            }}
          >
            <div className="col-8">
              <b style={{ fontSize: `20px`, color: `#26BBBA` }}>
                Programa <b>NO AGE</b>: exclusivo para 50+{" "}
              </b>
              <br />
              <p>
                O NO AGE nasceu com o propósito de quebrar paradigmas, estimular
                a diversidade das gerações, apoiando profissionais maduros que
                acreditam que a capacidade de realização é uma questão de
                mentalidade e da forma como se posiciona.
              </p>
              <p>
                Com projetos de mentoria e design de carreira, contribuímos para
                que as pessoas conquistem seus objetivos pessoais e/ou
                profissionais, pois acreditamos que o mundo está aberto para
                quem tem apetite por aprender, experimentar e se reinventar.
                Escolha onde quer estar e se permita.
              </p>
              <p>
                Se você quer impactar o mundo, contribuindo com sua experiência,
                clique aqui que vamos construir junto um processo de
                desenvolvimento que ajude a atingir seus objetivos.
              </p>
            </div>
            <div className="col-4" style={{ textAlign: `center` }}>
              <Img
                fluid={data.imagemSeniores.childImageSharp.fluid}
                className="kg-image"
              />
              <br />
              <br />
              <Link to="/contato" className="calltoaction-button">
                Agende um bate-bapo
              </Link>
            </div>
          </div>

          <br />

          <div
            id="ImagemComportamento"
            className="row"
            style={{
              width: `100%`,
              padding: "10px",
              textAlign: "left",
              background: "#ffffff",
              fontSize: "16px",
              verticalAlign: "center",
            }}
          >
            <div className="col-4" style={{ textAlign: `center` }}>
              <Img
                fluid={data.imagemComportamento.childImageSharp.fluid}
                className="kg-image"
              />

              <br />
              <br />
              <Link to="/contato" className="calltoaction-button">
                Entre em contato e saiba mais
              </Link>
            </div>
            <div className="col-8">
              <b style={{ fontSize: `20px`, color: `#26BBBA` }}>
                Imagem & Comportamento
              </b>
              <br />
              <p>
                Bastam apenas alguns segundos para que sua imagem pessoal
                impacte as pessoas com quem se relaciona, sendo interpretada e
                definindo SUA MARCA. Por esta razão é tão importante construir
                uma imagem autêntica e coerente com seu comportamento e
                objetivos.
              </p>
              <p>
                Trabalhar a Imagem&Comportamento é criar possibilidades de
                conexões por meio de uma estratégia de comunicação que transmita
                ao mundo informações importantes sobre suas escolhas,
                personalidade e valores, impactando em seus resultados pessoais
                e profissionais.
              </p>
              <p>
                Tudo começa por você, por suas escolhas e seus desejos. Sua
                imagem deve traduzir sua essência!
              </p>
            </div>
          </div>
          <br />
          <hr />
          <h5>Depoimentos</h5>
          <div className="row">
            <div
              className="col-12"
              style={{ textAlign: `left`, fontSize: `0.8em` }}
            >
              <p>
                <i>
                "Sou muito grata ao seu trabalho porque você  facilitou o meu processo
                de organização mental e me mostrou o quanto é importante a organização mental. 
                Eu sou muito organizada externamente [agenda, checklist, to do list], 
                internamente não e esse era meu maior desafio. A mentoria em design de 
                carreira e negócio me ajudou muito a encontrar o meu porquê, facilitando na 
                elaboração do mapa de valor (value proposition) e serviços do meu novo negócio."
                </i>
                <br />
                <b>- LA, Empreendedora e Consteladora, 34 anos</b>
                <br />
                <b>Objetivo:</b> Design de Carreira e Negócio.
              </p>
            </div>
            <div
              className="col-12"
              style={{ textAlign: `left`, fontSize: `0.8em` }}
            >
              <p>
                <i>
                  "Obrigada pela orientação do meu projeto de design de
                  carreira! Extremamente profissional, tornou o caminho leve e
                  seguro, respeitou meu momento, meu tempo, abriu portas e
                  proporcionou networking durante todo o processo, me
                  proporcionando uma evolução profissional e fortalecimento
                  pessoal. Muito feliz pela oportunidade de trabalhar com você!"
                </i>
                <br />
                <b>- CO, Empreendedora atuando no Varejo, 50 anos</b>
                <br />
                <b>Objetivo:</b> Autoconhecimento como estratégia para desenhar
                alternativas de reposicionamento da carreira.
              </p>
            </div>
            <div
              className="col-12"
              style={{ textAlign: `left`, fontSize: `0.8em` }}
            >
              <p>
                <i>
                  "A mentoria de carreira com você tem sido uma experiência de
                  muito aprendizado e totalmente transformadora na minha
                  trajetória enquanto profissional de RH. Toda a sua dedicação,
                  energia, profissionalismo e bagagem em desenvolvimento humano
                  me ajudaram a enxergar novas perspectivas e me inspiraram
                  muito. Sou grata por esse novo capítulo da minha história, no
                  qual você me arrancou da minha zona de conforto e me
                  direcionou para novos patamares de atuação e posicionamento."
                </i>
                <br />
                <b>- PW, HRBP, 34 anos</b>
                <br />
                <b>Objetivo:</b> Construir sua marca profissional, conectando
                suas habilidades, experiência e personalidade para construção de
                rotas de Carreira na área de Recursos Humanos. Utilizar sua
                marca profissional como principal estratégia para conquistar seu
                próximo passo da carreira.
              </p>
            </div>
            <div
              className="col-12"
              style={{ textAlign: `left`, fontSize: `0.8em` }}
            >
              <p>
                <i>
                  "Que a intensidade do meu muito obrigada permita expressar a
                  gratidão pelo esforço e energia que você dedicou ao meu
                  direcionamento. Você transcende a esfera profissional e trata
                  um tema tão delicado e cheio de bagagem emocional com uma
                  leveza incrível, colocando a gente no eixo e canalizando a
                  ansiedade em ações que criam atalhos para o alcance do
                  objetivo final. Se hoje estou feliz, realizada, me sentindo
                  fortalecida por receber uma proposta de mercado em meio a uma
                  pandemia, cenário mais improvável possível, é graças a você.
                  Só posso pedir ao Papai do céu que te abençoe, que te de muita
                  saúde física, emocional, financeira e acima de tudo te de
                  ainda mais sabedoria para transformar todos os seus problemas
                  em soluções, deixando sua caixa de ferramentas mais completa.
                  Começo e termino te dizendo MUITO OBRIGADA DE!"
                </i>
                <br />
                <b>- TB, Gerente de CX, 38 anos</b>
                <br />
                <b>Objetivo:</b> Transitar da cultura organizacional tradicional
                para startup. Identificar o FIT do seu perfil com Startups.
                Planejar e construir rotas para conquistar seu objetivo.
              </p>
            </div>
            <div
              className="col-12"
              style={{ textAlign: `left`, fontSize: `0.8em` }}
            >
              <p>
                <i>
                  "A Denise me acompanhou durante um processo de transição de
                  carreira que apesar de planejado, foi muito desafiador. Com
                  suas habilidades diferenciadas, ela me ajudou a identificar
                  minhas fortalezas e me guiou no caminho de aprendizagem de
                  como melhor explorá-las. Profissional incrível, com visão
                  humanizada e de negócios. Algo que encontrei em poucos
                  profissionais de RH."
                </i>
                <br />
                <b>- FP, Gerente de Novos Negócios e Inovação, 39 anos</b>
                <br />
                <b>Objetivo:</b> Fortalecer a autoconfiança por meio do
                autoconhecimento. Construir marca e narrativa profissional,
                valorizando fortalezas e garantindo melhor posicionamento no
                mercado.
              </p>
            </div>

            <div
              className="col-12"
              style={{ textAlign: `left`, fontSize: `0.8em` }}
            >
              <p>
                <i>
                  "A experiência do processo de mentoria orientado para o meu
                  desenvolvimento como profissional de RH contribuiu para
                  colocar iniciativas em ação e despertar insights de novos
                  projetos. Todo conteúdo foi essencial, estruturado e acelerou
                  meu aprendizado. Ganhei auto confiança. Sempre grata à você
                  Denise Barbosa!"
                </i>
                <br />
                <b>- KP, Head de Pessoas&Projetos, 45 anos</b>
                <br />
                <b>Objetivo:</b> Estruturar e implementar projetos de
                Desenvolvimento Humano&Organizacional, posionando a área como
                parceira estratégica das pessoas e do negócio.
              </p>
            </div>
          </div>

          <br />
          <div className="row" style={{ borderTop: `1px solid #000` }}>
            <br />
            <h3 className="post-content-title">
              Para saber mais, me manda mensagem no formulário abaixo:
            </h3>
            <FormContato />
          </div>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    designCarreira: file(
      relativePath: { eq: "para-voce-designercarreira.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    imagemComportamento: file(relativePath: { eq: "para-voce-imagem.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    imagemSeniores: file(
      relativePath: { eq: "para-voce-senioresesenioras.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <ParaVoce location={props.location} data={data} {...props} />
    )}
  />
)
